import axios from 'axios';
import settings from '../../assets/data/settings.json';
import BASE_URL from '../config';
import {
  FETCH_REQUEST,
  FETCH_USER_DETAILS,
  FETCH_FAILURE,
  FETCH_LOGOUT,
  FETCH_IAM_USER_LIST,
  FETCH_IAM_FAILURE,
  USER_IAM_ONBOARD_SUCCESS,
  USER_IAM_ONBOARD_FAILURE,
  RESET_ADD_USER_DATA,
  CHECK_IF_USER_EXIST,
  CHECK_IF_USER_EXIST_FAILURE,
  CHECK_IF_USER_EXIST_SUCCESS,
  ADD_USER_DETAILS_TO_DASHBOARD,
  FETCH_ALL_DASHBOARD_USER_LIST,
  FETCHED_ADVERTISERS_FOR_USER_BASED_ON_EMAIL,
  FETCHED_USER_TYPE,
  USER_DETAILS_STORED_SUCCESS,
  USER_UI_SETTINGS_ON_TIMEZONE
} from './userTypes';
axios.defaults.timeout = 10000; // time in ms to handle logout session
const timeout = 120000; // time in ms to handle logout session
// const BASE_URL = window.location.origin; //process.env.REACT_APP_INTERNAL_SERVER_URL;

export const fetchFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: []
  };
};
export const fetchRequest = (msg) => {
  //console.log("fetchRequest-->")

  return {
    type: FETCH_REQUEST,
    payload: []
  };
};
export const fetchLogout = (error) => {
  // console.log("fetch logout");
  return {
    type: FETCH_LOGOUT,
    payload: error
  };
};
export const fetchIAMFailure = (error) => {
  // console.log("fetch logout");
  return {
    type: FETCH_IAM_FAILURE,
    payload: error
  };
};
export const userDetailsFetched = (details) => {
  //console.log(details);

  return {
    type: FETCH_USER_DETAILS,
    payload: details.data
  };
};
export const userPlatformFetched = (details) => {
  // console.log(details.platform);

  return {
    type: FETCH_USER_PLATFORMS,
    payload: details.platform
  };
};
export const userIAMListFetched = (details) => {
  //console.log(details);
  return {
    type: FETCH_IAM_USER_LIST,
    payload: details
  };
};
export const setUserTimeZoneSettingsBasedOnSelection = (details) => {
  //console.log('details', details);
  return {
    type: USER_UI_SETTINGS_ON_TIMEZONE,
    payload: details
  };
};
export const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
export const deleteCookieByName = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
export const fetchUserDetails = () => (dispatch, getState) => {
  let token;
  //console.log('process.env', process.env);
  // console.log(
  //   'process.env.REACT_APP_PRODUCTION',
  //   process.env.REACT_APP_PRODUCTION,
  //   process.env.REACT_APP_INTERNAL_SERVER_URL,
  //   process.env.REACT_APP_INTERNAL_SERVER_URL
  // );

  // if (process.env.REACT_APP_PRODUCTION == "false") {
  //   token = getCookie("_im_auth_token"); //For staging
  // } else {
  //   token = getCookie("_prod_im_auth_token"); // For Prod
  // }

  if (BASE_URL.includes('dashboard.inmobi.com')) {
    console.log('BASE_URL redirect prod ===>', BASE_URL);
    token = getCookie('_prod_im_auth_token'); // For Prod
  } else if (BASE_URL.includes('dspexternaldashboardstaging')) {
    console.log('BASE_URL redirect staging_utc ===>', BASE_URL);
    token = getCookie('_im_auth_token'); //For staging
  } else if (BASE_URL.includes('dspexternalreportingdashboardjst')) {
    console.log('BASE_URL redirect staging_jst ===>', BASE_URL);
    token = getCookie('_im_auth_token'); //For staging
  } else {
    console.log('Unknown environment. Redirecting to default', BASE_URL);
    token = getCookie('_im_auth_token'); // For Prod
  }

  axios({
    method: 'get',
    url: `${BASE_URL}/userDetails`,

    headers: {
      'Content-Type': 'application/json',
      userToken: token
    }
  })
    .then((response) => {
      //console.log(response.data);
      if (response.data.httpCode == 401) {
        return dispatch(fetchFailure(response.data));
      }
      return dispatch(userDetailsFetched(response.data));
    })
    .catch((err) => {
      return dispatch(fetchFailure('logout'));
    });
};

export const fetchIAMUserList = () => (dispatch, getState) => {
  let token;
  if (process.env.REACT_APP_PRODUCTION == 'false') {
    token = getCookie('_im_auth_token'); //For staging
  } else {
    token = getCookie('_prod_im_auth_token'); // For Prod
  }
  axios({
    method: 'post',
    url: `${BASE_URL}/getOnboardedUsersList`,

    headers: {
      'Content-Type': 'application/json',
      // userToken: getCookie("_im_auth_token"),//For staging
      userToken: token // For Prod
    }
  })
    .then((response) => {
      //console.log(response.data.length);
      //console.log(response.data.data.data.records.length);
      if (response.data.data.data.records.length > 0) {
        //console.log(response.data)
        return dispatch(userIAMListFetched(response.data.data.data.records));
      } else {
        return dispatch(fetchIAMFailure('Not able to fetch data. Please retry'));
      }
    })
    .catch((err) => {
      return dispatch(fetchIAMFailure('logout'));
    });
};

export const logoutUser = () => (dispatch) => {
  // deleteCookieByName("_im_auth_token")//staging

  if (process.env.REACT_APP_PRODUCTION == 'false') {
    //token = getCookie("_im_auth_token"); //For staging
    deleteCookieByName('_im_auth_token');
  } else {
    // token = getCookie("_prod_im_auth_token"); // For Prod
    deleteCookieByName('_prod_im_auth_token');
  }

  return dispatch(fetchLogout('logout'));
};
export const userIAMOnboardSuccess = (details) => {
  //console.log(details);
  return {
    type: USER_IAM_ONBOARD_SUCCESS,
    payload: details
  };
};
export const userIAMOnboardFailure = (error) => {
  //console.log(details);
  return {
    type: USER_IAM_ONBOARD_FAILURE,
    payload: error
  };
};
export const sendIAMInvite = (details) => (dispatch) => {
  let token;
  if (process.env.REACT_APP_PRODUCTION == 'false') {
    token = getCookie('_im_auth_token'); //For staging
  } else {
    token = getCookie('_prod_im_auth_token'); // For Prod
  }
  //console.log('inside this one', details);
  let fnDetails = details.name.split(' ');
  let fname = fnDetails[0];
  let lname = fnDetails[1] ? fnDetails[1] : '';
  //console.log(fname, lname);
  var data = {
    email: details.email,
    firstName: fname,
    lastName: lname,
    displayName: details.name
  };
  axios({
    method: 'post',
    url: `${BASE_URL}/sendIAMInvite`,
    headers: {
      'Content-Type': 'application/json',
      // userToken: getCookie("_im_auth_token"),//For staging
      userToken: token // For Prod
    },
    data: {
      email: details.email,
      firstName: fname,
      lastName: lname,
      displayName: details.name
    }
  }).then((response) => {
    // console.log(response.data);
    if (response.data.data.email != '') {
      return dispatch(userIAMOnboardSuccess(response.data.data));
    } else {
      return dispatch(userIAMOnboardFailure('Unable To Onboard IAM'));
    }
  });
};
export const resetIAMFormData = () => {
  return {
    type: RESET_ADD_USER_DATA,
    payload: []
  };
};
export const userAlreadyExist = (details) => {
  //console.log(details);
  return {
    type: CHECK_IF_USER_EXIST,
    payload: details
  };
};
export const userAlreadyExistFailure = (error) => {
  //console.log(details);
  return {
    type: CHECK_IF_USER_EXIST_FAILURE,
    payload: error
  };
};
export const saveUserDetailsSuccess = () => {
  return {
    type: USER_DETAILS_STORED_SUCCESS,
    payload: []
  };
};
export const fetchedDashboardAllUserList = (details) => {
  //console.log(details);
  return {
    type: FETCH_ALL_DASHBOARD_USER_LIST,
    payload: details
  };
};
export const fetchedUserAssociatedAdvertisersByEmailId = (utcAdvertisers, jstAdvertisers, org_names) => {
  return {
    type: FETCHED_ADVERTISERS_FOR_USER_BASED_ON_EMAIL,
    payload: {
      utcAdvertisers: utcAdvertisers,
      jstAdvertisers: jstAdvertisers,
      orgNames: org_names
    }
  };
};
export const checkUserAlreadyExist = (email) => (dispatch) => {
  //console.log('inside this one', email);
  axios({
    method: 'post',
    url: `${BASE_URL}/checkUserExistDashboard`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      email: email
    }
  })
    .then((response) => {
      //console.log(response.data);
      if (response.data.status == true) {
        return dispatch(userAlreadyExist(true));
      } else {
        return dispatch(userAlreadyExist(false));
      }
    })
    .catch((err) => {
      return dispatch(userAlreadyExist(false));
    });
};
export const userDashboardOnboardSuccess = (details) => {
  //console.log(details);
  return {
    type: ADD_USER_DETAILS_TO_DASHBOARD,
    payload: details
  };
};
export const saveUserDetailsFailure = (error) => {
  return {
    type: ADD_USER_DETAILS_FAILURE,
    payload: error
  };
};
// To onboard user to Dashboard
export const onboardUserToDashboard = (data) => (dispatch) => {
  //console.log(data);
  axios({
    method: 'post',
    url: `${BASE_URL}/onboardUserAndAdvertiserDetails`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
    .then((response) => {
      // console.log(response.data);
      if (response.data.status == true) {
        return dispatch(userDashboardOnboardSuccess(true));
      } else {
        return dispatch(userDashboardOnboardSuccess(false));
      }
    })
    .catch((err) => {
      return dispatch(userAlreadyExist(false));
    });
};
// Get all users onboarded to dashboard List not IAM
export const fetchAllDashboardUserList = () => (dispatch) => {
  axios({
    method: 'post',
    url: `${BASE_URL}/get_all_dashboard_users_list`,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((response) => {
      //console.log(response.data);
      if (response.data.length != 0) {
        return dispatch(fetchedDashboardAllUserList(response.data.data));
      } else {
        return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      }
    })
    .catch((err) => {
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
    });
};
const groupByTimezone = (data) => {
  return data.reduce((acc, item) => {
    const timezone = item.timezone;
    if (!acc[timezone]) {
      acc[timezone] = [];
    }
    acc[timezone].push(item);
    return acc;
  }, {});
};
// Get advertisers mapped for user using email id for JST and UTC
export const fetchAdvertisersListForUserBasedOnEmailId = (email) => (dispatch) => {
  axios({
    method: 'post',
    url: `${BASE_URL}/get_user_advertiser_details`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      email: email
    }
  })
    .then((response) => {
      //console.log(response.data.length);
      if (response.data.length != 0) {
        //console.log('actual length', response.data.data);
        const selectedData = response.data.data.filter((item) => item.selected === 'true');

        const orgNames = selectedData.map((item) => item.org_name);
        // Use Set to remove duplicates
        const uniqueOrgNames = [...new Set(orgNames)];
        //console.log(uniqueOrgNames);
        // For
        const filteredData = response.data.data.filter((item) => !uniqueOrgNames.includes(item.org_name));
        //console.log(filteredData.length);
        const utcTimeZoneAdvertisers = filteredData;
        const jstTimeZoneAdvertisers = response.data.data;
        // const groupedData = groupByTimezone(filteredData);
        //console.log(utcTimeZoneAdvertisers, jstTimeZoneAdvertisers);
        return dispatch(fetchedUserAssociatedAdvertisersByEmailId(utcTimeZoneAdvertisers, jstTimeZoneAdvertisers, uniqueOrgNames));
      } else {
        return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      }
    })
    .catch((err) => {
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
    });
};
export const fetchUserTypeFailure = (error) => {
  //console.log('inside fetch user details failure ===>');
  return {
    type: FETCH_USER_TYPE_ERROR,
    payload: []
  };
};
export const setUserType = (advertiser_list) => {
  //console.log('advertiser_list', advertiser_list);
  return {
    type: FETCHED_USER_TYPE,
    payload: {
      user_type: advertiser_list.role
    }
  };
};
// To fetch user role based on email provided already this exist in advertiserActions
export const fetchAdvertiserListForProvidedEmail = (email) => (dispatch) => {
  //console.log(email)

  dispatch(fetchRequest);
  axios({
    method: 'post',
    url: `${BASE_URL}/getadvertiserListForUser`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: email
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log('response advertiser', response.data);
      if (response.data.data.length > 0) {
        return dispatch(setUserType(response.data.data[0]));
      } else {
        //console.log("inside fetchAdvertiserListFailure");
        return dispatch(fetchUserTypeFailure(''));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error);
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};

export const UpdateUserAdvertiserSettings = (data) => (dispatch) => {
  //console.log(email)

  dispatch(fetchRequest);
  axios({
    method: 'post',
    url: `${BASE_URL}/update_user_advertiser_settings`,
    header: {
      'Content-Type': 'application/json'
    },
    data: data,
    timeout: timeout
  })
    .then((response) => {
      //console.log('response advertiser', response.data.data);
      if (response.data.data.length > 0) {
        return dispatch(saveUserDetailsSuccess(response.data.data[0]));
      } else {
        //console.log("inside fetchAdvertiserListFailure");
        return dispatch(saveUserDetailsFailure('Unable To Onboard USer'));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error);
      return dispatch(fetchFailure('Unable To Onboard USer'));
      //return Promise.reject(error)
    });
};
export const setTimeZoneSettings = (timezone, IsSkan) => (dispatch) => {
  //console.log(timezone, IsSkan);
  //console.log(settings);
  let selected_timezone;
  if (timezone == 'UTC' && IsSkan == true) {
    //console.log('UTC IOS', settings.ios_utc);
    selected_timezone = settings.ios_utc;
  }
  if (timezone == 'UTC' && IsSkan == false) {
    //console.log('UTC Android', settings.android_utc);
    selected_timezone = settings.android_utc;
  }
  if (timezone == 'JST' && IsSkan == false) {
    //console.log('JST Non Skan', settings.non_skan_jst);
    selected_timezone = settings.non_skan_jst;
  }
  if (timezone == 'JST' && IsSkan == true) {
    // console.log('JST Skan', settings.skan_jst);
    selected_timezone = settings.skan_jst;
  }
  //console.log('selected_timezone', selected_timezone);
  dispatch(setUserTimeZoneSettingsBasedOnSelection(selected_timezone));
};

export const fetchUserPlatforms = (emailId) => (dispatch, getState) => {
  let token;
  //console.log('process.env', process.env);

  const BASE_URL = process.env.REACT_APP_INTERNAL_SERVER_URL; //window.location.origin

  axios({
    method: 'post',
    url: `${BASE_URL}/checkUserPlatform`,

    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      email: emailId
    }
  })
    .then((response) => {
      if (response.data.httpCode == 401) {
        return dispatch(fetchFailure(response.data));
      }
      return dispatch(userPlatformFetched(response.data));
    })
    .catch((err) => {
      //return dispatch(fetchFailure("logout"));
      return dispatch(fetchFailure('Unable to fetch User Platform'));
    });
};
