import axios from 'axios';
import {
  FETCH_BUNDLE_REPORTING_REQUEST,
  FETCH_BUNDLE_REPORTING_DATA,
  FETCH_BUNDLE_REPORTING_ERROR,
  RESET_REPORT_VIEW_DATA
} from './reportingTypes';
import BASE_URL from '../config';
axios.defaults.timeout = 12000; // time in ms to handle logout session
const timeout = 120000; // time in ms to handle logout session
export const fetchBundleReportRequest = () => {
  return {
    type: FETCH_BUNDLE_REPORTING_REQUEST,

    payload: []
  };
};
export const fetchBundleReportFailure = (error) => {
  return {
    type: FETCH_BUNDLE_REPORTING_ERROR,
    payload: error
  };
};
export const resetReportData = () => {
  return {
    type: RESET_REPORT_VIEW_DATA,
    payload: []
  };
};
//For Non Skan
export const fetchReportDataForNonSkanReporting = (campaignList) => (dispatch, getState) => {
  //console.log('process fetching data');
  dispatch(fetchBundleReportRequest());
  axios({
    method: 'post',
    url: `${BASE_URL}/getNonSkanBundleDetails`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      CampaignIds: campaignList,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone
    },
    timeout: timeout
  })
    .then((response) => {
      if (response.data.length > 0) {
        //console.log(response.data);
        return dispatch(fetchDataForReporting(response.data[0].bundle));
      } else {
        return dispatch(fetchBundleReportFailure('Not able to fetch data. Please retry'));
      }
    })
    .catch(function (error) {
      if (error.response.data.statusCode == 404) {
        dispatch(fetchBundleReportFailure('No Data Found'));
      } else {
        return dispatch(fetchBundleReportFailure('Not able to fetch data. Please retry'));
      }

      //return Promise.reject(error)
    });
};
export const fetchReportDataForSkanReporting = (campaignList) => (dispatch, getState) => {
  //console.log("process",process.env);
  dispatch(fetchBundleReportRequest());
  axios({
    method: 'post',
    url: `${BASE_URL}/getSkanBundleDetails`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      userEmail: getState().userReducer.userEmail,
      CampaignIds: campaignList,
      advertiser_id: localStorage.getItem('advertiserId'),
      sfId: localStorage.getItem('sfId'),
      startDate: getState().campaignReducer.start_date,
      endDate: getState().campaignReducer.end_date,
      timeZone: getState().advertiserReducer.timeZone
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log(response.data);
      if (response.data.length > 0) {
        //console.log(response.data[0].bundles)
        return dispatch(fetchDataForReporting(response.data[0].bundles));
      } else {
        return dispatch(fetchBundleReportFailure);
      }
    })
    .catch(function (error) {
      //console.log('Show error notification!', error, error.response);
      if (error.response.data.statusCode == 404) {
        dispatch(fetchBundleReportFailure('No Data Found'));
      } else {
        return dispatch(fetchBundleReportFailure('Not able to fetch data. Please retry'));
      }
      // console.log(
      //   "Show error notification!",
      //   error,
      //   error.response.data.error,
      // );

      //return Promise.reject(error)
    });
};
function detectKeys(jsonData) {
  const keys = new Set();

  jsonData.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      keys.add(key);
    });
  });
  return Array.from(keys);
}
export const fetchDataForReporting = (data) => {
  //console.log(data)
  // Call the function with your JSON data
  const detectedKeys = detectKeys(data);
  //console.log("detectedKeys",detectedKeys)
  const reportDetails = data.map((item, id = index) => ({ id, ...item }));
  return {
    type: FETCH_BUNDLE_REPORTING_DATA,
    payload: reportDetails
  };
};
