import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, CardActions, CardContent, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';
import DataCard from 'ui-component/cards/DataCard';
import DataLoadingCard from 'ui-component/cards/DataLoadingCard';
import { getStaticWidgetsData, getStaticWidgetsDataForSkan } from '../../redux/campaign/campaignActions';
const WidgetContainerIap = ({
  getStaticWidgetsData,
  impressions,
  clicks,
  mediaCost,
  installs,
  skanInstalls,
  kpiEvent,
  skanEvents,
  selected_campaigns,
  isSkan,
  getStaticWidgetsDataForSkan,
  widzardLoading,
  start_date,
  end_date,
  mmpEvents,
  overAllDisplaySettings,
  isDollar,
  totalMediaCostLocalCurrency,
  timeZone
}) => {
  //console.log('overAllDisplaySettings', clicks);

  const round = (n, dp) => {
    const h = +'1'.padEnd(dp + 1, '0'); // 10 or 100 or 1000 or etc
    return Math.round(n * h) / h;
  };
  function formatNumberAbbreviated(number) {
    const suffixes = ['', 'K', 'M', 'B'];
    if (number < 1000) {
      //return round(number,1);
      return Math.round(number * 100) / 100;
    }
    const formatNumber = (num) => {
      const roundedNum = Math.round(num * 100) / 100; // Round to 2 decimal places
      return roundedNum.toString().replace(/\.0+$/, ''); // Remove trailing zeros after decimal point
    };

    const suffixIndex = Math.floor(Math.log10(Math.abs(number)) / 3);
    const abbreviatedNumber = number / Math.pow(10, suffixIndex * 3);

    return `${formatNumber(abbreviatedNumber)}${suffixes[suffixIndex]}`;
  }
  function fetchCalculation(type, value) {
    //console.log('type', type, value);
    if (value == 0 || value == undefined) {
      return '-';
    }
    let result = 0;
    if (type == 'impressions') {
      //result=round(value/1000,2)+"K";
      //console.log(value);

      result = formatNumberAbbreviated(value);
    }
    if (type == 'clicks') {
      result = formatNumberAbbreviated(value);
      // if(value>1000){
      //     result=round(value/1000,2)+"K";
      // }
      // else{
      //     result=value;
      // }
    }
    if (type == 'mediaCost') {
      // result="$"+round(value,2);
      if (isDollar == true) {
        result = '$ ' + formatNumberAbbreviated(value);
      } else {
        result = '¥ ' + formatNumberAbbreviated(value);
      }
    }
    if (type == 'installs') {
      result = formatNumberAbbreviated(value);
    }

    return result;
  }
  return (
    <>
      <Grid item lg={2} md={6} sm={6} xs={12}>
        {widzardLoading ? (
          <DataLoadingCard content={false} title="Clicks" />
        ) : (
          <DataCard content={false} title="Clicks" value={fetchCalculation('clicks', clicks)} />
        )}
      </Grid>
      <Grid item lg={2} md={6} sm={6} xs={12}>
        {widzardLoading ? (
          <DataLoadingCard content={false} title="Installs" />
        ) : (
          <DataCard content={false} title="Installs" value={fetchCalculation('installs', installs)} />
        )}
      </Grid>
      <Grid item lg={2} md={6} sm={6} xs={12}>
        {widzardLoading ? (
          <DataLoadingCard content={false} title="Media Cost" />
        ) : (
          <DataCard
            content={false}
            title="Media Cost"
            value={fetchCalculation('mediaCost', isDollar ? mediaCost : totalMediaCostLocalCurrency)}
          />
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    impressions: state.IAPReducer.totalImpressions,
    clicks: state.IAPReducer.totalClicks,
    mediaCost: state.IAPReducer.totalMediaCost,
    installs: state.IAPReducer.totalInstalls,
    selected_campaigns: state.advertiserReducer.selected_campaigns,
    widzardLoading: state.IAPReducer.loading,
    isDollar: state.campaignReducer.isDollar,
    totalMediaCostLocalCurrency: state.IAPReducer.totalMediaCostLocalCurrency,
    timeZone: state.advertiserReducer.timeZone
  };
};
const mapDispatchToProps = {
  getStaticWidgetsData: getStaticWidgetsData,
  getStaticWidgetsDataForSkan: getStaticWidgetsDataForSkan
};
export default connect(mapStateToProps, mapDispatchToProps)(WidgetContainerIap);
