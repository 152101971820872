import axios from 'axios';
import {
  FETCH_REQUEST,
  FETCH_ADVERTISER_LIST,
  FETCH_ERROR,
  FETCH_CAMPAIGN_LIST,
  SET_ADVERTISER,
  FETCH_CAMPAIGN_LIST_BY_ADVERTISER,
  SET_CAMPIGN_LIST_SELECTED,
  FETCH_ADVERTISER_REQUEST,
  FETCH_CAMPAIGN_ERROR,
  FETCH_ADVERTISER_ERROR,
  FETCH_ANDROID_CAMPAIGN_COUNT,
  FETCH_IOS_CAMPAIGN_COUNT,
  RESET_CAMPAIGN_COUNT,
  FETCH_ORG_LIST,
  SET_ADVERTISER_FILTERED_DATA,
  FETCHED_USER_ROLE
} from './advertiserTypes';
import advertiserData from 'assets/data/advertiser.json';
import BASE_URL from '../config';
import campaignData from 'assets/data/campaigns.json';
axios.defaults.timeout = 12000; // time in ms to handle logout session
const timeout = 120000; // time in ms to handle logout session

export const fetchFailure = (error) => {
  return {
    type: FETCH_ERROR,
    payload: []
  };
};

export const fetchAdvertiserListFailure = (error) => {
  //console.log('inside fetchAdvertiserListFailure===>');

  return {
    type: FETCH_ADVERTISER_ERROR,
    payload: []
  };
};
export const fetchRequest = (msg) => {
  //console.log("fetchRequest-->")

  return {
    type: FETCH_REQUEST,
    payload: []
  };
};
export const fetchCampaignError = (msg) => {
  return {
    type: FETCH_CAMPAIGN_ERROR,
    payload: msg
  };
};
export const fetchAdvertiserData = (msg) => {
  //console.log('fetching=====>');
  return {
    type: FETCH_ADVERTISER_REQUEST,
    payload: []
  };
};
export const resetCampaignCount = (msg) => {
  return {
    type: RESET_CAMPAIGN_COUNT,
    payload: msg
  };
};
export const setFetchedAdvertiserList = (advertiser_list) => {
  //console.log('advertiser_list', advertiser_list);
  return {
    type: FETCH_ADVERTISER_LIST,
    payload: {
      advertiser_list: advertiser_list.filter((data) => data.selected == 'true')

      //user_type: "admin", //advertiser_list.role
    }
  };
};
export const androidCampaignCount = (count) => {
  return {
    type: FETCH_ANDROID_CAMPAIGN_COUNT,
    payload: {
      android_campaign_count: count
    }
  };
};
export const iosCampaignCount = (count) => {
  return {
    type: FETCH_IOS_CAMPAIGN_COUNT,
    payload: {
      ios_campaign_count: count
    }
  };
};

export const fetchedOrgDetails = (details) => {
  return {
    type: FETCH_ORG_LIST,
    payload: details
  };
};
// To fetch user associated advertisers along with role type
export const fetchAdvertiserList = (email) => (dispatch) => {
  //console.log(email)

  dispatch(fetchRequest);
  axios({
    method: 'post',
    url: `${BASE_URL}/get_user_advertiser_details`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      email: email
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log('response advertiser', response.data.data);
      if (response.data.data.length > 0) {
        return dispatch(setFetchedAdvertiserList(response.data.data));
      } else {
        //console.log("inside fetchAdvertiserListFailure");
        return dispatch(fetchAdvertiserListFailure(''));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error);
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};

export const setUserRole = (advertiser_list) => {
  //console.log('advertiser_list', advertiser_list);
  return {
    type: FETCHED_USER_ROLE,
    payload: {
      user_type: advertiser_list.role
    }
  };
};
export const fetchUserRoleByEmailId = (email) => (dispatch) => {
  //console.log(email)

  dispatch(fetchRequest);
  axios({
    method: 'post',
    url: `${BASE_URL}/getUserRoleBasedOnEmail`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      email: email
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log('response advertiser', response.data.data);
      if (response.data.data.length > 0) {
        return dispatch(setUserRole(response.data.data[0]));
      } else {
        //console.log("inside fetchAdvertiserListFailure");
        return dispatch(fetchAdvertiserListFailure(''));
      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error);
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};

export const fetchCampaignsForAdvertiser = (advertiser_guid, sfid) => (dispatch) => {
  dispatch(fetchAdvertiserData(''));
  axios({
    method: 'post',
    url: `${BASE_URL}/getCampaignListForAdvertiser`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      AdvertiserId: advertiser_guid
    },
    timeout: timeout
  })
    .then((response) => {
      // console.log("Activated----><<",response.data.data.length)
      if (response.data.data.length > 0) {
        // console.log(response.data.data)
        return dispatch(setFetchedCampaignList(response.data.data, sfid));
      } else {
        return dispatch(fetchCampaignError('No campaigns are live on Android for this Advertiser.'));
      }
    })
    .catch(function (error) {
      // console.log(
      //   "Show error notification!",
      //   error,
      //   error.response.data.error,
      // );
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};
export const fetchJSTCampaignsForAdvertiser = (advertiser_guid, sfid) => (dispatch,getState) => {
  let timeZone=getState().advertiserReducer.timeZone
  dispatch(fetchAdvertiserData(''));
  axios({
    method: 'post',
    url: `${BASE_URL}/getJSTCampaignListForAdvertiser`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      AdvertiserId: advertiser_guid
    },
    timeout: timeout
  })
    .then((response) => {
      // console.log("Activated----><<",response.data.data.length)
      if (response.data.data.length > 0) {
        // console.log(response.data.data)
        return dispatch(setFetchedCampaignList(response.data.data, sfid));
      } else {
        return dispatch(fetchCampaignError('No campaigns are live on Non-Skan for this Advertiser.'));
      }
    })
    .catch(function (error) {
      // console.log(
      //   "Show error notification!",
      //   error,
      //   error.response.data.error,
      // );
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};
export const fetchCampaignsCountForAndroid = (advertiser_guid, sfid) => (dispatch) => {
  dispatch(resetCampaignCount(''));
  axios({
    method: 'post',
    url: `${BASE_URL}/getCampaignListForAdvertiser`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      AdvertiserId: advertiser_guid
    },
    timeout: timeout
  })
    .then((response) => {
      // console.log("Activated----><<",response.data.data.length)
      if (response.data.data.length > 0) {
        // console.log(response.data.data)
        return dispatch(androidCampaignCount(1));
      } else {
        return dispatch(androidCampaignCount(0));
      }
    })
    .catch(function (error) {
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
    });
};
export const fetchSkanCampaignsCountForIos = (advertiser_guid, sfid) => (dispatch) => {
  dispatch(resetCampaignCount(''));

  axios({
    method: 'post',
    url: `${BASE_URL}/getSkanCampaignListForAdvertiser`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      AdvertiserId: advertiser_guid
    },
    timeout: timeout
  })
    .then((response) => {
      if (response.data.data.length > 0) {
        return dispatch(iosCampaignCount(1));
      } else {
        return dispatch(iosCampaignCount(0));
      }
    })
    .catch(function (error) {
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
    });
};

export const setFetchedCampaignList = (campaignData, sfId) => {
  //console.log("campaign_list",campaignData)
  return {
    type: FETCH_CAMPAIGN_LIST_BY_ADVERTISER,
    payload: {
      campaign_list: campaignData,
      // default_selected_campaigns: campaignData.map(
      //   (value) => value.campaign_guid,
      // ).slice(0,2),
      default_selected_campaigns: campaignData.map((value) => value.campaign_guid),
      sfId: sfId
    }
  };
};

export const fetchCampaignsByAdvertiserGuid = (advertiser_guid) => {
  let campaignDetailsForAdvertiser = campaignData.filter((data) => data.advertiserID == advertiser_guid);
  // const selected_Advertiser=getState().advertiserReducer.advertiser_guid;
  //console.log("Action",campaignDetailsForAdvertiser)
  return {
    type: FETCH_CAMPAIGN_LIST_BY_ADVERTISER,
    payload: campaignDetailsForAdvertiser
  };
};
export const setSelectedAdvertiserData = (details) => {
  //console.log(details);
  return {
    type: SET_ADVERTISER,
    payload: details
  };
};
export const setSelectedAdvertiser = (advertiser_name, advertiser_guid) => (dispatch) => {
  //console.log('setSelectedAdvertiser', advertiser_name, advertiser_guid);
  let data = {
    advertiser_guid: advertiser_guid,
    advertiser_name: advertiser_name
  };
  dispatch(setSelectedAdvertiserData(data));
  //  return {
  //         type:SET_ADVERTISER,
  //         payload:data
  //  }
};
// set selected campaign guids as selected by autocomplete
export const setSelectedCampaignList = (campaign_guids) => {
  //console.log('campaign_guids', campaign_guids);
  return {
    type: SET_CAMPIGN_LIST_SELECTED,
    payload: campaign_guids
  };
};
export const fetchSkanCampaignsForAdvertiser = (advertiser_guid, sfid) => (dispatch,getState) => {
  let timeZone=getState().advertiserReducer.timeZone
  dispatch(fetchRequest);
  //console.log("Activated---->")
  axios({
    method: 'post',
    url: `${BASE_URL}/getSkanCampaignListForAdvertiser`,
    header: {
      'Content-Type': 'application/json'
    },
    data: {
      AdvertiserId: advertiser_guid
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log("Activated----><<", response.data.data.length);
      if (response.data.data.length > 0) {
        //console.log(response.data.data);
        return dispatch(setFetchedCampaignList(response.data.data, sfid));
      } else {
        //console.log("nside elsex");
      let msg=(timeZone=='JST'?'No campaigns are live on SKAN for this Advertiser.':'No campaigns are live on iOS for this Advertiser.')
        return dispatch(fetchCampaignError(msg));
      }
    })
    .catch(function (error) {
      // console.log(
      //   "Show error notification!",
      //   error,
      //   error.response.data.error,
      // );
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
      //return Promise.reject(error)
    });
};
export const fetchOrgList = () => (dispatch) => {
  axios({
    method: 'post',
    url: `${BASE_URL}/get_org_details`,
    header: {
      'Content-Type': 'application/json'
    },
    timeout: timeout
  })
    .then((response) => {
      if (response.data.data.length > 0) {
        //console.log(response.data.data);
        return dispatch(fetchedOrgDetails(response.data.data));
      } else {
        return dispatch(fetchedOrgDetails([]));
      }
    })
    .catch(function (error) {
      return dispatch(fetchFailure('Not able to fetch data. Please retry'));
    });
};
export const filterAdvertiserData = (advertiser_list, search_string) => (dispatch) => {
  //console.log(advertiser_list);
  if (search_string != '') {
    advertiser_list = advertiser_list.filter((item) => item.advertiser_name.toLowerCase().includes(search_string.toLowerCase()));
  }
  //console.log(advertiser_list);
  dispatch(setFilteredAdvertiserData(advertiser_list));
};

export const setFilteredAdvertiserData = (details) => {
  return {
    type: SET_ADVERTISER_FILTERED_DATA,
    payload: details
  };
};
